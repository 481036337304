export const FIELD_TYPES={
    DEFAULT:'DEFAULT',
    CUSTOM:'CUSTOM',
}

export const GOOGLE_FONTS = [
    { family: "ABeeZee" },
    { family: "Abel" },
    { family: "Abril Fatface" },
    { family: "Acme" },
    { family: "Advent Pro" },
    { family: "Alata" },
    { family: "Alegreya" },
    { family: "Alegreya Sans" },
    { family: "Alegreya Sans SC" },
    { family: "Aleo" },
    { family: "Alfa Slab One" },
    { family: "Alice" },
    { family: "Almarai" },
    { family: "Amatic SC" },
    { family: "Amiri" },
    { family: "Antic Slab" },
    { family: "Anton" },
    { family: "Antonio" },
    { family: "Architects Daughter" },
    { family: "Archivo" },
    { family: "Archivo Black" },
    { family: "Archivo Narrow" },
    { family: "Arimo" },
    { family: "Arsenal" },
    { family: "Arvo" },
    { family: "Asap" },
    { family: "Asap Condensed" },
    { family: "Assistant" },
    { family: "Baloo 2" },
    { family: "Bangers" },
    { family: "Barlow" },
    { family: "Barlow Condensed" },
    { family: "Barlow Semi Condensed" },
    { family: "Be Vietnam Pro" },
    { family: "Bebas Neue" },
    { family: "Bitter" },
    { family: "Bodoni Moda" },
    { family: "Bree Serif" },
    { family: "Cabin" },
    { family: "Cairo" },
    { family: "Cantarell" },
    { family: "Cardo" },
    { family: "Carter One" },
    { family: "Catamaran" },
    { family: "Caveat" },
    { family: "Chakra Petch" },
    { family: "Changa" },
    { family: "Chivo" },
    { family: "Cinzel" },
    { family: "Comfortaa" },
    { family: "Commissioner" },
    { family: "Concert One" },
    { family: "Cookie" },
    { family: "Cormorant" },
    { family: "Cormorant Garamond" },
    { family: "Courgette" },
    { family: "Crete Round" },
    { family: "Crimson Pro" },
    { family: "Crimson Text" },
    { family: "Cuprum" },
    { family: "DM Sans" },
    { family: "DM Serif Display" },
    { family: "DM Serif Text" },
    { family: "Dancing Script" },
    { family: "Didact Gothic" },
    { family: "Domine" },
    { family: "Dosis" },
    { family: "EB Garamond" },
    { family: "El Messiri" },
    { family: "Encode Sans" },
    { family: "Exo" },
    { family: "Exo 2" },
    { family: "Faustina" },
    { family: "Figtree" },
    { family: "Fira Sans" },
    { family: "Fira Sans Condensed" },
    { family: "Fira Sans Extra Condensed" },
    { family: "Fjalla One" },
    { family: "Francois One" },
    { family: "Frank Ruhl Libre" },
    { family: "Gelasio" },
    { family: "Gloria Hallelujah" },
    { family: "Gothic A1" },
    { family: "Great Vibes" },
    { family: "Gruppo" },
    { family: "Handlee" },
    { family: "Heebo" },
    { family: "Hind" },
    { family: "Hind Madurai" },
    { family: "Hind Siliguri" },
    { family: "IBM Plex Mono" },
    { family: "IBM Plex Sans" },
    { family: "IBM Plex Sans Arabic" },
    { family: "IBM Plex Sans Condensed" },
    { family: "IBM Plex Serif" },
    { family: "Inconsolata" },
    { family: "Indie Flower" },
    { family: "Inter" },
    { family: "Josefin Sans" },
    { family: "Josefin Slab" },
    { family: "Jost" },
    { family: "Jura" },
    { family: "K2D" },
    { family: "Karla" },
    { family: "Kavivanar" },
    { family: "Kavoon" },
    { family: "Knewave" },
    { family: "KoHo" },
    { family: "Kosugi" },
    { family: "Krub" },
    { family: "Lalezar" },
    { family: "Lato" },
    { family: "Lekton" },
    { family: "Lexend" },
    { family: "Libre Barcode 128" },
    { family: "Libre Baskerville" },
    { family: "Libre Caslon Display" },
    { family: "Libre Caslon Text" },
    { family: "Libre Franklin" },
    { family: "Life Savers" },
    { family: "Lilita One" },
    { family: "Lobster" },
    { family: "Lobster Two" },
    { family: "Londrina Outline" },
    { family: "Lora" },
    { family: "Lusitana" },
    { family: "M PLUS 1p" },
    { family: "M PLUS Rounded 1c" },
    { family: "Mada" },
    { family: "Maitree" },
    { family: "Mandali" },
    { family: "Manjari" },
    { family: "Marck Script" },
    { family: "Markazi Text" },
    { family: "Marko One" },
    { family: "Marmelad" },
    { family: "Martel" },
    { family: "Martel Sans" },
    { family: "Marvel" },
    { family: "Mate" },
    { family: "Mate SC" },
    { family: "Maven Pro" },
    { family: "Merienda" },
    { family: "Merriweather" },
    { family: "Merriweather Sans" },
    { family: "Michroma" },
    { family: "Mitr" },
    { family: "Modak" },
    { family: "Mogra" },
    { family: "Molengo" },
    { family: "Monofett" },
    { family: "Monda" },
    { family: "Monoton" },
    { family: "Montserrat" },
    { family: "Montserrat Alternates" },
    { family: "Montserrat Subrayada" },
    { family: "Mouse Memoirs" },
    { family: "Mukta" },
    { family: "Mukta Mahee" },
    { family: "Mukta Malar" },
    { family: "NTR" },
    { family: "Nanum Gothic" },
    { family: "Nanum Myeongjo" },
    { family: "Nanum Pen Script" },
    { family: "Neuton" },
    { family: "Niramit" },
    { family: "Nixie One" },
    { family: "Nobile" },
    { family: "Nokora" },
    { family: "Noto Sans" },
    { family: "Noto Serif" },
    { family: "Noto Serif JP" },
    { family: "Noto Serif KR" },
    { family: "Nunito" },
    { family: "Nunito Sans" },
    { family: "Nunito Sans JP" },
    { family: "Nunito Sans KR" },
    { family: "Odibee Sans" },
    { family: "Old Standard TT" },
    { family: "Open Sans" },
    { family: "Open Sans Condensed" },
    { family: "Oranienbaum" },
    { family: "Oswald" },
    { family: "Overpass" },
    { family: "Overpass Mono" },
    { family: "Pacifico" },
    { family: "Padauk" },
    { family: "Paprika" },
    { family: "Pattaya" },
    { family: "Pavanam" },
    { family: "Paytone One" },
    { family: "Philosopher" },
    { family: "Play" },
    { family: "Playfair Display" },
    { family: "Playfair Display SC" },
    { family: "Podkova" },
    { family: "Poiret One" },
    { family: "Pontano Sans" },
    { family: "Poppins" },
    { family: "Pragati Narrow" },
    { family: "Prata" },
    { family: "Press Start 2P" },
    { family: "Proza Libre" },
    { family: "Puritan" },
    { family: "Quattrocento" },
    { family: "Quattrocento Sans" },
    { family: "Questrial" },
    { family: "Rajdhani" },
    { family: "Raleway" },
    { family: "Raleway Dots" },
    { family: "Ramabhadra" },
    { family: "Rasa" },
    { family: "Red Hat Display" },
    { family: "Red Hat Text" },
    { family: "Rhodium Libre" },
    { family: "Ribeye" },
    { family: "Righteous" },
    { family: "Risque" },
    { family: "Roboto" },
    { family: "Roboto Condensed" },
    { family: "Roboto Mono" },
    { family: "Roboto Slab" },
    { family: "Rochester" },
    { family: "Rock Salt" },
    { family: "Rokkitt" },
    { family: "Romanesco" },
    { family: "Ropa Sans" },
    { family: "Rosario" },
    { family: "Rozha One" },
    { family: "Rubik" },
    { family: "Rubik Mono One" },
    { family: "Ruda" },
    { family: "Rufina" },
    { family: "Ruslan Display" },
    { family: "Russo One" },
    { family: "Sahitya" },
    { family: "Saira" },
    { family: "Saira Condensed" },
    { family: "Saira Extra Condensed" },
    { family: "Saira Semi Condensed" },
    { family: "Salsa" },
    { family: "Sancreek" },
    { family: "Sansita" },
    { family: "Sarabun" },
    { family: "Sarala" },
    { family: "Sarina" },
    { family: "Sarpanch" },
    { family: "Satisfy" },
    { family: "Scope One" },
    { family: "Sedgwick Ave" },
    { family: "Seymour One" },
    { family: "Shadows Into Light" },
    { family: "Shanti" },
    { family: "Share" },
    { family: "Share Tech" },
    { family: "Shippori Mincho" },
    { family: "Sigmar One" },
    { family: "Simonetta" },
    { family: "Skranji" },
    { family: "Spectral" },
    { family: "Spectral SC" },
    { family: "Spicy Rice" },
    { family: "Squada One" },
    { family: "Sree Krushnadevaraya" },
    { family: "Sriracha" },
    { family: "Sue Ellen Francisco" },
    { family: "Sunflower" },
    { family: "Supermercado One" },
    { family: "Suravaram" },
    { family: "Taviraj" },
    { family: "Teko" },
    { family: "Tenali Ramakrishna" },
    { family: "Text Me One" },
    { family: "Thasadith" },
    { family: "Timmana" },
    { family: "Tinos" },
    { family: "Titan One" },
    { family: "Titillium Web" },
    { family: "Tomorrow" },
    { family: "Trade Winds" },
    { family: "Trocchi" },
    { family: "Tulpen One" },
    { family: "Ubuntu" },
    { family: "Ubuntu Condensed" },
    { family: "Ubuntu Mono" },
    { family: "Ultra" },
    { family: "Uncial Antiqua" },
    { family: "Underdog" },
    { family: "Unica One" },
    { family: "UnifrakturCook" },
    { family: "UnifrakturMaguntia" },
    { family: "Unkempt" },
    { family: "Unlock" },
    { family: "Vampiro One" },
    { family: "Varela" },
    { family: "Varela Round" },
    { family: "Varta" },
    { family: "Vesper Libre" },
    { family: "Vibes" },
    { family: "Vibur" },
    { family: "Vidaloka" },
    { family: "Viga" },
    { family: "Vollkorn" },
    { family: "Vollkorn SC" },
    { family: "Wallpoet" },
    { family: "Warnes" },
    { family: "Wellfleet" },
    { family: "Yanone Kaffeesatz" },
    { family: "Yantramanav" },
    { family: "Yatra One" },
    { family: "Yellowtail" },
    { family: "Yeon Sung" },
    { family: "Yeseva One" },
    { family: "Yesteryear" },
    { family: "Yrsa" },
    { family: "Zilla Slab" },
    { family: "Zilla Slab Highlight" },
  ];
  
  // You can now use top250WithoutLoad as your updated JSON object.
  